<template>
    <Setup-Step
        fluid
        @next="f => f()"
        :loadingMsg="loadingMsg"
        :settings="settings"
        showNext
        step="order-approval"
        title="Order Approval">
        <template v-slot:body>
            <BT-Order-Cycle-Header showTrimmings highlightApprove title="Order Pipeline: Approve" />

            <Setup-Block>
                <v-list dense>
                    <v-list-item><strong class="mr-2">Scope:</strong>Defined by the supplier for each individual customer.</v-list-item>
                    <v-list-item><strong class="mr-2">Options: </strong>Auto or Manual</v-list-item>
                </v-list>

                <v-divider class="my-4" />

                <p>
                    An order needs to be approved by the supplier before going further in the pipeline.  
                    The supplier can have this done automatically by BlitzIt Web or manually by a user.  
                    This only applies when customers place their own orders.  
                    If a supplier creates an order on the customer's behalf, it will immediately be marked as approved by BlitzIt Web.  
                </p>
                <p>
                    A customer can edit their order at any time until after the order is approved.  After that they need to ask the supplier to change their order.
                </p>

                <v-divider class="my-4" />

                <Setup-Example>
                    Peter's Pears have a long history with their customer Delectable Desserts and have the system set to automatically approve any new orders from this customer.
                    They have also recently started supplying Jam Session and want to manually approve any new orders for the first 6 months or so.
                </Setup-Example>
                
                <Setup-Example>
                    Andy's Apricots enter all their customers' orders into the system themselves.  So this stage is irrelevant, because every order is immediately marked as approved.
                </Setup-Example>

            </Setup-Block>
            
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Order-Approval-Setup',
    components: {
        BTOrderCycleHeader: () => import('~components/BT-Order-Cycle-Header.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupExample: () => import('~home/setup/Setup-Example.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    }
}
</script>